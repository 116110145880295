var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "basic-card-block" }, [
    _c("h1", { staticClass: "sub-module-title" }, [
      _vm._v(_vm._s(_vm.title) + " "),
      _vm.actions.length > 0
        ? _c(
            "span",
            _vm._l(_vm.actions, function (action) {
              return _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  style: { margin: "2px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: action.disabled,
                  },
                  on: { click: action.onAction },
                },
                [_vm._v(_vm._s(action.label))]
              )
            }),
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }